.loading {
  width: 100%;
  text-align: center;
  width: 34px;
  height: 24px;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
}

button .loading {
  position: relative;
  margin-bottom: -10px !important;
}

.btn-sm .loading {
  margin-top: -10px;
  margin-bottom: -10px !important;
}

.loading div {
  width: 100%;
  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;
  margin-top: -10px;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite loading;
  position: absolute;
}

.loading div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes loading {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    top: 18px;
    left: 18px;
  }

  100% {
    width: 36px;
    height: 36px;
    opacity: 0;
    top: 0;
    left: 0;
  }
}

.loading.gray div {
  border: 4px solid #888;
}

.stockloading button.tocart:before {
  display: none !important;
}

.stockloading .button-goto {
  height: 44px;
}

.stockloading .button-goto:before {
  display: none !important;
}

/*# sourceMappingURL=index.3af7d1b2.css.map */
