.loading {
    width: 100%;
    text-align: center;
    display: inline-block;
    position: relative;
    width: 34px;
    height: 24px;
    margin: auto;
    display: flex;
    justify-content: center;
}

button .loading {
    position: relative;
    margin-bottom: -10px !important;
}

.btn-sm .loading {
    margin-top: -10px;
    margin-bottom: -10px !important;
}

.loading div {
    width: 100%;
    margin-top: -10px;
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes loading {
    0% {
        top: 18px;
        left: 18px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 36px;
        height: 36px;
        opacity: 0;
    }
}

.loading.gray div {
    border: 4px solid #888
}

.stockloading button.tocart::before {
    display: none !important;
}

.stockloading .button-goto {
    height: 44px;
}

.stockloading .button-goto::before {
    display: none !important;
}